<template>
  <div class="back">
    <div class="back_inner" :style="{'width':winWidth+'px'}">
      <div class="top_white" :style="{'width':winWidth+'px'}">
        <div class="top_back" @click="go_back()">
          <van-image :src="left_img" width="9" height="15" :show-loading="false" />
          <span style="margin-left:10px">配送地址</span>
        </div>
      </div>
      <div style="height:1.5rem"></div>
      <van-form @submit="onSubmit" ref="vanForm">
        <van-field
          v-model="address_info.name"
          name="用户名"
          label="收货人"
          placeholder="请填写收货人"
          :rules="[{ required: true, message: '请填写收货人' }]"
          maxlength="10"
        />
        <van-field
          v-model="address_info.phone"
          name="phone"
          type="Number"
          label="手机号码"
          placeholder="收件人电话号码"
          maxlength="11"
          :rules="[
                         {required: true,message:'请填写电话号码'}
                        ,{ pattern, message: '请填写正确的电话号码' }]"
        />
        <van-field
          readonly
          clickable
          name="area"
          :value="long_area"
          label="所在地区"
          placeholder="省市区县、乡镇等"
          @click="showArea = true"
          :rules="[{ required: true, message: '请选择所在地区' }]"
        />
        <van-popup v-model="showArea" position="bottom">
          <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
        </van-popup>
        <van-field
          v-model="address_info.description"
          rows="3"
          autosize
          label="详细地址"
          type="textarea"
          placeholder="小区、门牌号等"
          maxlength="50"
          :rules="[{ required: true, message: '请填写详细地址' }]"
        />
        <van-field name="switch" label="设置该地址为默认收货地址">
          <template #input>
            <div class="switch_bar">
              <van-switch v-model="address_info.default_status" size="20" />
            </div>
          </template>
        </van-field>
      </van-form>
      <div class="go_it_outer" :style="{'width':winWidth+'px'}">
        <div class="go_it" @click="$refs.vanForm.submit()">保存并使用</div>
      </div>
    </div>
  </div>
</template>
<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      left_img: "http://qiniupc.stylika.online/upload_pic_1615452264549_h=18",
      winWidth: 0,
      address_info: {
        area: "",
        city: "",
        default_status: false,
        description: "",
        name: "",
        phone: "",
        province: ""
      },
      long_area: "",
      pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
      areaList,
      showArea: false,
      isFirstAdd: false
    };
  },
  mounted() {
    this.winWidth = document.body.clientWidth;
    if (this.$route.query.data) {
      let queryData = JSON.parse(decodeURIComponent(this.$route.query.data));
      if (queryData.isFirstAdd) {
        this.address_info.default_status = queryData.isFirstAdd;
      } else {
          this.address_info = queryData
        this.long_area =
          this.address_info.province +
          " " +
          this.address_info.city +
          " " +
          this.address_info.area;
      }
    }
  },
  methods: {
    go_back() {
      this.$router.go(-1);
    },
    onConfirm(values) {
      this.long_area = values
        .filter(item => !!item)
        .map((item, index) => {
          if (index == 0) {
            this.address_info.province = item.name;
          } else if (index == 1) {
            this.address_info.city = item.name;
          } else if (index == 2) {
            this.address_info.area = item.name;
          }
          return item.name;
        })
        .join(" ");
      this.showArea = false;
    },
    onSubmit() {
      let method = "post";
      let text = "添加成功！";
      if (this.address_info.address_id) {
        method = "put";
        text = "修改成功!";
      }
      this.$iHttp[method]("/api/shopping_car/user_address/", this.address_info)
        .then(res => {
          this.$toast.success(text);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.back {
  display: flex;
  justify-content: center;
  .back_inner {
    max-width: 800px;
    .top_white {
      position: fixed;
      top: 0;
      z-index: 1;
      width: 100%;
      background-color: white;
      display: flex;
      align-items: center;
      height: 1.4rem;
      border-bottom: 1px solid #f5f5f5;
      .van-image {
        width: 0.18rem;
        height: 0.34rem;
        margin-left: 0.5rem;
      }
      font-size: 17px;
      font-weight: 500;
      .top_back {
        display: flex;
        align-items: center;
      }
    }
    .van-form {
      .switch_bar {
        display: flex;
        align-items: center;
        height: 46px;
      }
    }
    .go_it_outer {
      position: fixed;
      background-color: white;
      border-top: 1px solid #f5f5f5;
      z-index: 1;
      bottom: 0;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      .go_it {
        background-color: black;
        color: white;
        height: 1rem;
        width: 3rem;
        border-radius: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
</style>